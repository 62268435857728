<template>
  <div>
    <Header :title="host.organisation ? host.organisation.name : null" :lead="null" :header="host.theme ? `https://api.tixgo.nl/files/` + host.theme.header_image : null" />

    <b-container fluid class="header-pull">
      <b-card class="mb-3" no-body>
        <b-card-header>
          <h4 class="fs-4 mb-0">Programma</h4>
        </b-card-header>
        <b-card-body>
          <Schedule target="_self" />
        </b-card-body>
      </b-card>
    </b-container>
  </div>
</template>
  
<script>
  import Header from '@/shared/components/Header';
  import Schedule from '@/shared/components/Schedule';

  export default {
    metaInfo: {
        title: 'Agenda',
    },
    components: {
      Header,
      Schedule,
    },
    data() {
        return {
          host: {},
        }
    },
    computed: {},
    methods: {
      getHost: function() {
        this.$axios.get('https://api.tixgo.nl/host/' + this.$route.params.host)
          .then( response => {
              this.host = response.data;
          }
        ).catch((error) => {
          if (error.response.status == '404') { 
            this.$router.push('/404');
          } else {
            this.$router.push('/500');
          }
        });
      },
    },
    created() {
      this.getHost();
    }
  }
</script>